<template>
  <div>
    <div class="login screen">
      <div class="row w-100 mx-0" style="height:100vh">
        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-6 h-100">
          <div class="leftBanner">
            <div class="overlap-group3">
              <div class="row" style="height:50%">
                <div class="col-12">
                  <div class="group-74">
                    <img
                      class="iposita-ondeman-ransparent-bg-1"
                      src="/images/iposita-ondemand1-alternate-transparent-bg-1-1.png"
                    />
                  </div>
                </div>
              </div>
              <div class="row" style="height:50%">
                <div class="col-12 mt-3">
                  <div class=" text-center text-3 roboto-light-white-login p-4">
                    <span class="roboto-light-white-login">The Logistics </span
                    ><span class="span11">Platform</span
                    ><span class="roboto-light-white-login">
                      built for you</span
                    >
                  </div>

                  <img class="bikes-1" src="/images/bikes-1-1.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
          <div class="right-bg" style="height:100%">
            <div class="align-items-center mt-4 p-5">
              <div class="auth-form-light text-left p-5 px-sm-5">
                <div class="text-center pb-3">
                  <h1 class="title roboto-black-black-50px pb-3">
                    <span class="roboto-black-black-50px login-title"
                      >Login</span
                    >
                  </h1>
                  <div class="text-4 roboto-light-black-17px">
                    <span class="roboto-light-black-17px login-desc"
                      >Enjoy all the different features available for you</span
                    >
                  </div>
                </div>

                <form
                  class="pt-2"
                  @submit.prevent="login"
                  id="scroll-to-login-Form"
                  ref="scrollTologinForm"
                >
                  <div class="form-group mt-2">
                    <strong class="roboto-normal-stack-17px pb-2 email1"
                      >Your email</strong
                    >

                    <MazInput
                      v-model="details.email"
                      :error="errors.email ? true : false"
                      :success="details.email && !errors.email ? true : false"
                      placeholder="Enter email"
                      :loading="isLoading ? true : false"
                      type="email"
                      autocomplete="new-email"
                      @input="formValidation()"
                      clearable
                    />

                    <code v-if="errors.email">
                      {{ errors.email[0] }}
                    </code>
                  </div>

                  <div class="form-group mt-3">
                    <strong class="roboto-normal-stack-17px pb-2 password1"
                      >Your password</strong
                    >

                    <MazInput
                      v-model="details.password"
                      :error="errors.password ? true : false"
                      :success="details.password ? true : false"
                      :loading="isLoading ? true : false"
                      type="password"
                      placeholder="Enter password"
                      autocomplete="new-password"
                      @input="formValidation()"
                      left-icon-name="lock"
                      clearable
                    />

                    <code v-if="errors.password">
                      {{ errors.password[0] }}
                    </code>
                  </div>

                  <div
                    class="mt-3"
                    v-if="errors.error && errors.error !== 'token_invalid'"
                  >
                    <MessageErrorFadeOut
                      class="p-2"
                      v-bind:message="errors.error"
                      v-bind:show="errors && errors.error ? true : false"
                    />
                  </div>

                  <div class="mt-3">
                    <button
                      type="submit"
                      :disabled="isLoading ? '' : isLoading"
                      class="btn btn-block btn-primary  btn-lg font-weight-medium auth-form-btn"
                    >
                      <span v-if="!isLoading"> Sign in</span>
                      <span v-if="isLoading">
                        <i class="fas fa-circle-notch fa-spin"></i> Signing
                        in...</span
                      >
                    </button>
                  </div>
                  <div
                    class="my-2 d-flex justify-content-between align-items-center"
                  >
                    <div class="form-check">
                      <label class="form-check-label text-muted">
                        <span class="span01 text-center"
                          >Don’t have an account yet?</span
                        ><span class="roboto-bold-bay-of-many-15px"
                          >&nbsp;
                        </span>
                        <a
                          href="javascript:void(0)"
                          class="span2 text-center"
                          @click="registerPage()"
                          >SIGN UP</a
                        >
                      </label>
                    </div>
                    <div class="forgot-passwordroboto-bold-sun-15px">
                      <a
                        href="javascript:void(0)"
                        @click="onResetPassword()"
                        class="auth-link text-black roboto-bold-sun-15px"
                        >Forgot password?</a
                      >
                    </div>
                  </div>

                  <div
                    class="box-container mt-3"
                    v-if="this.$route.params.userType === 'User'"
                  >
                    <div class="login-choice"><span>or Sign In with</span></div>
                    <div class="signup-buttons">
                      <div id="fb-root"></div>
                      <a
                        href="javascript:void(0)"
                        class="google-signup"
                        @click.prevent="loginWithGoogle"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          aria-hidden="true"
                        >
                          <title>Google</title>
                          <g fill="none" fill-rule="evenodd">
                            <path
                              fill="#4285F4"
                              d="M17.64 9.2045c0-.6381-.0573-1.2518-.1636-1.8409H9v3.4814h4.8436c-.2086 1.125-.8427 2.0782-1.7959 2.7164v2.2581h2.9087c1.7018-1.5668 2.6836-3.874 2.6836-6.615z"
                            ></path>
                            <path
                              fill="#34A853"
                              d="M9 18c2.43 0 4.4673-.806 5.9564-2.1805l-2.9087-2.2581c-.8059.54-1.8368.859-3.0477.859-2.344 0-4.3282-1.5831-5.036-3.7104H.9574v2.3318C2.4382 15.9832 5.4818 18 9 18z"
                            ></path>
                            <path
                              fill="#FBBC05"
                              d="M3.964 10.71c-.18-.54-.2822-1.1168-.2822-1.71s.1023-1.17.2823-1.71V4.9582H.9573A8.9965 8.9965 0 0 0 0 9c0 1.4523.3477 2.8268.9573 4.0418L3.964 10.71z"
                            ></path>
                            <path
                              fill="#EA4335"
                              d="M9 3.5795c1.3214 0 2.5077.4541 3.4405 1.346l2.5813-2.5814C13.4632.8918 11.426 0 9 0 5.4818 0 2.4382 2.0168.9573 4.9582L3.964 7.29C4.6718 5.1627 6.6559 3.5795 9 3.5795z"
                            ></path>
                          </g>
                        </svg>
                        Google
                      </a>
                      <a
                        href="javascript:void(0)"
                        class="facebook-signup"
                        @click.prevent="loginWithFacebook"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="#3578E5"
                        >
                          <path
                            d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"
                          />
                        </svg>
                        Facebook
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div class="align-items-center">
              <FooterBar />
            </div>
          </div>
        </div>
      </div>
    </div>

    <MazDialog v-model="isResetPassword" noFooter :persistent="true">
      <div slot="title">Find Your Account</div>
      <div class="row mt-2">
        <div class="col-lg-12">
          <ResetPassword @close="onCloseModal" />
        </div>
      </div>
    </MazDialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MessageErrorFadeOut from "../Common/Message/MessageErrorFadeOut";
import FooterBar from "../Layouts/FooterBar";
import ResetPassword from "../Auth/ResetPassword";

// import { initFbsdk } from '../../config/facebook_oAuth.js'
export default {
  name: "Login",

  data: function() {
    return {
      hasError: false,
      isLoading: false,
      params: null,
      formErrors: {},
      isResetPassword: false,
      userType: "",
      details: {
        email: null,
        password: null,
        device_type: "android",
        device_token: this.getDeviceId(),
        device_id: "no device",
        userType: this.userType,
      },
    };
  },
  components: {
    MessageErrorFadeOut,
    FooterBar,
    ResetPassword,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters(["errors", "isSuccess"]),
  },

  mounted() {
    this.$store.commit("setErrors", {});
    this.$store.commit("setIsSuccess", false);

    this.scrollTologin();
    //  initFbsdk();
  },

  methods: {
    ...mapActions("auth", [
      "sendLoginRequest",
      "sendLoginWithGoogleRequest",
      "sendLoginWithFacebookRequest",
    ]),
  
    registerPage: function() {
      return (window.location.href =
        "/register/" + this.$route.params.userType);
    },
    loginWithGoogle() {
      this.$gAuth
        .signIn()
        .then((GoogleUser) => {
          // on success do something
          // console.log('GoogleUser', GoogleUser)
          // console.log('getId', GoogleUser.getId())
          // console.log('basicprofile', GoogleUser.getBasicProfile().getName())
          // console.log('getBasicProfile', GoogleUser.getBasicProfile())
          // console.log('getAuthResponse', GoogleUser.getAuthResponse())

          let google_access_token = GoogleUser.getAuthResponse().access_token;
          let google_form = {
            device_type: "android",
            device_token: this.getDeviceId(),
            device_id: "no device",
            accessToken: google_access_token,
            login_by: "google",
          };

          this.isLoading = true;
          this.details.userType = this.$route.params.userType
            ? this.$route.params.userType
            : "User";
          this.sendLoginWithGoogleRequest(google_form)
            .then(() => {
              this.isLoading = false;
              localStorage.setItem("role", this.details.userType);
              if (this.isSuccess) {
                this.details = {};
                return (window.location.href =
                  "/home/" + this.$route.params.userType);
              }
            })
            .catch((error) => {
              console.log(" error", error);
            });
        })
        .catch((error) => {
          console.log("signOut error", error);
        });
    },
    loginWithFacebook() {
      alert("comming soon!");
      // window.FB.login(response => {
      //   if (response && response.authResponse) {

      //     window.FB.api(`/${response.authResponse.userID}`, userResponse => {
      //       if (userResponse) {

      //            let fb_access_token=response.authResponse.accessToken;
      //             let google_form={
      //                 device_type: 'android',
      //               device_token: this.getDeviceId(),
      //                 device_id: 'no device',
      //               accessToken:fb_access_token,
      //               login_by:'facebook'
      //             }

      //      this.isLoading = true;
      //   this.details.userType=this.$route.params.userType?this.$route.params.userType:'User';
      //   this.sendLoginWithGoogleRequest(google_form).then(() => {
      //     this.isLoading = false;
      //     localStorage.setItem("role",  this.details.userType);
      //     if (this.isSuccess) {

      //       this.details = {};
      //      return  window.location.href="/home/"+this.$route.params.userType
      //     }

      //   })
      //   .catch(error => {
      //     console.log(' error', error)
      //   })
      //       }
      //     }, this.params);
      //     // router.push('/home')
      //   }
      // }, this.params)
    },
    scrollTologin() {
      const el = this.$refs.scrollTologinForm;

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    getDeviceId() {
      var navigator_info = window.navigator;
      var screen_info = window.screen;
      var uid = navigator_info.mimeTypes.length;
      uid += navigator_info.userAgent.replace(/\D+/g, "");
      uid += navigator_info.plugins.length;
      uid += screen_info.height || "";
      uid += screen_info.width || "";
      uid += screen_info.pixelDepth || "";
      return uid;
    },
    formValidation() {
      this.hasError = false;
      this.formErrors = {};

      if (!this.details.password) {
        this.formErrors.password = ["Password required."];
        this.hasError = true;
      }

      if (!this.details.email) {
        this.formErrors.email = ["Email required."];
        this.hasError = true;
      }

      if (!this.validEmail(this.details.email)) {
        this.formErrors.email = ["Valid email required."];
        this.hasError = true;
      }

      if (this.hasError) {
        this.$store.commit("setErrors", this.formErrors);

        return false;
      }
      return true;
    },
    validEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    onResetPassword() {
      this.isResetPassword = !this.isResetPassword;
    },
    onCloseModal(){
 this.isResetPassword =false;
    },
    login: function() {
      if (!this.formValidation()) {
        return false;
      }
      this.isLoading = true;
      this.details.userType = this.$route.params.userType
        ? this.$route.params.userType
        : "User";
      this.sendLoginRequest(this.details).then(() => {
        this.isLoading = false;
        localStorage.setItem("role", this.details.userType);
        if (this.isSuccess) {
          this.details = {};
          return (window.location.href =
            "/home/" + this.$route.params.userType);
        }
      });
    },
  },
};
</script>
<style>
.invalid-feedback {
  color: rgb(231, 209, 4) !important;
}

:root {
  --black: rgba(0, 0, 0, 1);
  --bay-of-many: rgba(31, 66, 140, 1);
  --stack: rgba(138, 138, 138, 1);
  --sun: rgba(249, 178, 24, 1);
  --white: rgba(255, 255, 255, 1);

  --font-size-m: 10px;
  --font-size-l: 12px;
  --font-size-xl: 15px;
  --font-size-xxl: 17px;
  --font-size-xxxl: 31px;
  --font-size-xxxxl: 50px;

  --font-family-roboto: "Roboto";
}

@import url("https://fonts.googleapis.com/css?family=Roboto:400,700,900,300,500");

.login:before {
  position: fixed !important;
  background: rgba(31, 66, 140, 1);
}

.login:before {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background: rgba(31, 66, 140, 1);
  width: 50%;
  height: 100%;
}

.login a {
  display: contents;
  text-decoration: none;
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

.login {
  background-color: var(--white);
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  width: 100%;
}

.login .overlap-group2 {
  height: 458px;
  left: 720px;
  position: absolute;
  top: 436px;
  width: 720px;
}

.roboto-light-white-login {
  color: var(--white);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xxxl);
  font-weight: 300;
  font-style: normal;
}

@media only screen and (max-width: 768px) {
  .screen:before {
    position: fixed !important;
    background: rgba(31, 66, 140, 1);
  }
  .login-title {
    color: #ffff !important;
  }

  .login-desc {
    color: #ffff !important;
  }
  .span01 {
    color: #ffff !important;
  }
  .email1 {
    color: #ffff !important;
  }
  .password1 {
    color: #ffff !important;
  }
  .span2 {
    color: var(--sun) !important;
  }
  .copyright-text {
    color: #ffff !important;
  }
  .maz-input__input {
    /* background-image: url("/images/rectangle-30-1.png");
    background-size: 100% 100%;
      border-radius: 7px; */
    border-color: var(--sun);
  }
  .maz-input {
    /* position: relative;
    background-color: none;
    transition: none;
    height: 1rem;
    min-height: 2.5rem; */
    border-color: var(--sun);
  }

  .btn-primary {
    color: #fff;
    background-color: var(--sun) !important;
    border-color: var(--sun) !important;
    line-height: 1 !important;
    font-weight: 400 !important;
    border-radius: 15px !important;
  }
  .login:before {
    content: "";
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    background: rgba(31, 66, 140, 1);
    width: 100%;
    height: 100%;
  }
  .roboto-light-white-login {
    color: var(--white) !important;
    font-family: var(--font-family-roboto) !important;
    font-size: var(--font-size-xxl) !important;
    font-weight: 300 !important;
    font-style: normal;
  }

  .login .span11 {
    color: var(--sun);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-xxl);
    font-weight: 700;
  }
  .p-5 {
    padding: 0px !important;
  }
}

.roboto-black-black-50px {
  color: var(--black);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xxxxl);
  font-weight: 900;
  font-style: normal;
}

.roboto-normal-stack-17px {
  color: var(--stack);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xxl);
  font-weight: 400;
  font-style: normal;
}

.roboto-medium-black-15px {
  color: var(--black);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xl);
  font-weight: 500;
  font-style: normal;
}

.roboto-bold-bay-of-many-15px {
  color: var(--bay-of-many);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xl);
  font-weight: 700;
  font-style: normal;
}

.roboto-normal-black-10px {
  color: var(--black);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}

.roboto-light-black-17px {
  color: var(--black);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xxl);
  font-weight: 300;
  font-style: normal;
}

.roboto-normal-stack-12px {
  color: var(--stack);
  font-family: var(--font-family-roboto) !important;
  font-size: var(--font-size-l) !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

.roboto-bold-sun-15px {
  color: var(--sun) !important;
  font-family: var(--font-family-roboto) !important;
  font-size: var(--font-size-xl) !important;
  font-weight: 700 !important;
  font-style: normal !important;
}

.login .mask-group {
  height: 417px;
  left: 0;
  position: absolute;
  top: 42px;
  width: 720px;
}

.login .forgot-password {
  left: 477px;
  letter-spacing: 0;
  position: absolute;
  top: 94px;
}

.login .text-1 {
  letter-spacing: 0;
}

.login .span01 {
  color: var(--black);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xl);
  font-weight: 400;
}

.login .span2 {
  color: var(--bay-of-many);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xl);
  font-weight: 900;
}

.login .group-76 {
  height: 73px;
  left: 119px;
  position: absolute;
  top: 0;
  width: 487px;
}

.login .password {
  left: 0;
  letter-spacing: 0;
  position: absolute;
  top: 0;
}

.login .overlap-group {
  background-image: url("/images/rectangle-30-1.png");
  background-size: 100% 100%;
  height: 45px;
  left: 0;
  position: absolute;
  top: 28px;
  width: 481px;
}

.login .form-control {
  background-image: url("/images/rectangle-30-1.png");
  background-size: 100% 100%;
  border: none;
}

.login .min-8-characters {
  left: 45px;
  letter-spacing: 0;
  position: absolute;
  top: 12px;
}

.login .show-password {
  left: 366px;
  letter-spacing: 0;
  position: absolute;
  top: 15px;
}

.login .group-9 {
  height: 45px;
  left: 119px;
  position: absolute;
  top: 162px;
  width: 481px;
}

.login .group-77 {
  height: 20px;
  left: 116px;
  position: absolute;
  top: 93px;
  width: 130px;
}

.login .remember-me {
  left: 29px;
  letter-spacing: 0;
  position: absolute;
  top: 1px;
}

.login .vector {
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.login .group-81 {
  height: 30px;
  left: 194px;
  position: absolute;
  top: 386px;
  width: 332px;
}

.login .group {
  height: 8px;
  left: 0;
  position: absolute;
  top: 11px;
  width: 8px;
}

.login .address {
  letter-spacing: 0;
  white-space: nowrap;
}

.login .text-5 {
  letter-spacing: 0;
}

.login .overlap-group3 {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.login .group-74 {
  background-image: url("/images/mask-group-2.png");
  background-size: 100% 100%;
  height: 100%;
}

.login .right-bg {
  background-image: url("/images/mask-group-1.png");
  background-position: right bottom;
  /*Positioning*/
  background-repeat: no-repeat;
  /*Prevent showing multiple background images*/
}

.login .text-3 {
  letter-spacing: 0;
  white-space: nowrap;
}

.login .span11 {
  color: var(--sun);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
}

.login .iposita-ondeman-ransparent-bg-1 {
  /* height: 231px;
  left: 50%;
  object-fit: cover;
  position: absolute;
  top: 50px;
  width: 212px; */
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  top: 35%;
  height: 231px;
}

.login .bikes-1 {
  height: 170px;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* top: 10%; */
  /* width: 360px; */
}

.login .group-75 {
  height: 73px;
  left: 839px;
  position: absolute;
  top: 338px;
  width: 485px;
}

.login .email {
  left: 0;
  letter-spacing: 0;
  position: absolute;
  top: 0;
}

.login .text-2 {
  left: 45px;
  letter-spacing: 0;
  position: absolute;
  top: 11px;
}

.login .overlap-group4 {
  height: 115px;
  left: 907px;
  position: absolute;
  top: 177px;
  width: 346px;
}

.login .text-4 {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
}

.login .title {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
}
.maz-input__input {
  /* background-image: url("/images/rectangle-30-1.png");
    background-size: 100% 100%;
      border-radius: 7px; */
  border-color: var(--bay-of-many);
}
.maz-input {
  /* position: relative;
    background-color: none;
    transition: none;
    height: 1rem;
    min-height: 2.5rem; */
  border-color: var(--bay-of-many);
}

.auth form .auth-form-btn {
  padding: 1rem 3rem !important;
  line-height: 1 !important;
  font-weight: 400 !important;
  border-radius: 15px !important;
}

.btn-primary {
  color: #fff;
  background-color: rgba(31, 66, 140, 1);
  border-color: rgba(31, 66, 140, 1);
  line-height: 1 !important;
  font-weight: 400 !important;
  border-radius: 15px !important;
}
.box-container {
  padding: 20px;
  margin: 0 auto;
  width: 400px;
  box-shadow: beige;
  border-radius: 5px;
  background: #fff;
}
.btn-primary:hover,
.wizard > .actions a:hover {
  color: #fff;
  background-color: rgba(249, 178, 24, 1) !important;
  border-color: rgba(249, 178, 24, 1) !important;
}

.login-choice span {
  color: #5b6987;
  display: -ms-grid;
  display: grid;
  font-size: 16px;
  width: 100%;
  line-height: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -ms-grid-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
  grid-template-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
  grid-gap: 19px;
}
.login-choice span:after,
.login-choice span:before {
  content: "";
  border-top: 1px solid #e5e8ed;
}

.signup-buttons {
  margin-top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}
.facebook-signup,
.google-signup {
  color: #031b4e !important;
  background: #f2f8ff !important;
  border: 1px solid rgba(0, 105, 255, 0.2) !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  display: inline-block !important;
  margin-top: 0 !important;
  width: 47.5% !important;
  padding: 15px !important;
  text-align: center !important;
  position: inherit;
}
.signup-buttons a {
  vertical-align: middle;
  text-decoration: none;
}
.signup-buttons svg {
  left: 16px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.maz-dialog__header {
  background: rgba(249, 178, 24, 1) !important;
  color: #fff !important;
}

.maz-dialog--fullsize .maz-dialog__header {
  border-radius: 0;
  background: rgba(249, 178, 24, 1) !important;
  color: #fff !important;
}
</style>
