<template>
  <div>
    <div class="row mt-2">
      <div class="col-lg-12">
        <ValidationObserver v-if="!currentUser" v-slot="{ passes }">
          <form @submit.prevent="passes(sendOtp)" class="form">
            <strong class="roboto-normal-stack-17px pb-2"
              >Enter your recovery email address</strong
            >

            <ValidationProvider
              name="email"
              rules="required"
              v-slot="{ errors }"
            >
              <MazInput
                placeholder="Enter email"
                v-model="details.email"
                :loading="isLoading ? true : false"
                :name="`email`"
                autocomplete="email"
                clearable
              />
              <div class="row" style="margin-top: 10px;margin-bottom: 10px;">
                <div class="col-lg-6">
                  <code v-if="errors.length > 0">Email is required</code>
                </div>
              </div>
            </ValidationProvider>

            <button
              type="submit"
              style="background: rgba(31, 66, 140, 1);border-radius: 10px;"
              class="btn btn-primary btn-md btn-block font-weight-medium auth-form-btn"
            >
              <span v-if="isLoading">Sending ...</span>
              <span v-if="!isLoading">Send OTP</span>
            </button>
          </form>
        </ValidationObserver>

        <ValidationObserver v-if="currentUser && !allowToResetPasword" v-slot="{ passes }">
          <form @submit.prevent="passes(verifyOtp)" class="form">
            <strong class="roboto-normal-stack-17px pb-2">Verify OTP</strong>

            <ValidationProvider name="otp" rules="required" v-slot="{ errors }">
              <MazInput
                placeholder="Enter otp"
                v-model="details.otp"
                :loading="isLoading ? true : false"
                :name="`otp`"
                autocomplete="otp"
                clearable
              />
              <div class="row" style="margin-top: 10px;margin-bottom: 10px;">
                <div class="col-lg-6">
                  <code v-if="errors.length > 0">OTP is required</code>
                </div>
              </div>
            </ValidationProvider>

            <button
              type="submit"
              style="background: rgba(31, 66, 140, 1);border-radius: 10px;"
              class="btn btn-primary btn-md btn-block font-weight-medium auth-form-btn"
            >
              Verify
            </button>
          </form>
        </ValidationObserver>

        <ValidationObserver v-if="allowToResetPasword" v-slot="{ passes }">
          <form @submit.prevent="passes(changePassword)" class="form">
            <strong class="roboto-normal-stack-17px pb-2"
              >Reset Password</strong
            >

            <ValidationProvider name="password" rules="min:6" v-slot="{ errors }">
              <div class="form-group mt-3">
                <strong class="roboto-normal-stack-17px pb-2"
                  >New password</strong
                >
                <MazInput
                  v-model="details.password"
                  :loading="isLoading ? true : false"
                  type="password"
                  placeholder="Enter password"
                  autocomplete="new-password"
                  left-icon-name="lock"
                  clearable
                />

                <div class="row">
                  <div class="col-lg-12">
                     <code v-if="errors.length > 0"> 
                     The password field must have at least 6 characters
                     </code>
                  </div>
                </div>
              </div>
            </ValidationProvider>

              <ValidationProvider name="password_confirmation" rules="min:6" v-slot="{ errors }">
              <div class="form-group mt-3">
                <strong class="roboto-normal-stack-17px pb-2"
                  >Confirm password</strong
                >
                <MazInput
                  v-model="details.password_confirmation"
                  :loading="isLoading ? true : false"
                  type="password"
                  placeholder="Enter confirm password"
                  autocomplete="new-password-confirmation"
                  left-icon-name="lock"
                  clearable
                />

                <div class="row">
                  <div class="col-lg-12">
                     <code v-if="errors.length > 0"> 
                     The password confirmation field must have at least 6 characters
                     </code>
                  </div>
                </div>
              </div>
            </ValidationProvider>

            <button
              type="submit"
              style="background: rgba(31, 66, 140, 1);border-radius: 10px;"
              class="btn btn-primary btn-md btn-block font-weight-medium auth-form-btn"
            >
              <span v-if="isLoading">Submitting ...</span>
              <span v-if="!isLoading">Submit</span>
              
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ResetPassword",

  data() {
    return {
      isLoading: false,
      isResending: false,
      currentUser: null,
      allowToResetPasword: false,
      details: {
        otp: null,
        email: null,
        password: null,
        password_confirmation: null,
        id: null,
      },
      success: null,
      error: null,
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters(["errors", "isSuccess"]),
  },

  mounted() {
    Event.$on("getSentOTPUserError", (response) => {
      this.isLoading = false;

      this.$notify({
        group: "app",
        type: "error",
        title: "Forget password",
        text: response,
        duration: 10000,
        speed: 1000,
      });
    });

    Event.$on("getSentOTPUserMessage", (response) => {
      this.isLoading = false;

      this.$notify({
        group: "app",
        type: "success",
        title: "Forget password",
        text: response,
        duration: 10000,
        speed: 1000,
      });
    });

    Event.$on("getSentOTPUser", (response) => {
      this.isLoading = false;
      this.currentUser = response;
    });

     Event.$on("passwordChangedSuccess", (response) => {
        this.isLoading = false;
        this.currentUser=null;
        this.details=null;
        this.allowToResetPasword=false;

        this.$notify({
        group: "app",
        type: "success",
        title: "Reset password",
        text: response,
        duration: 10000,
        speed: 1000,
      });

       this.$emit('close');

    });
  },
  methods: {
    ...mapActions("auth", ["sendForgetPasswordOTP", "sendResetPasswordRequest"]),
    verifyOtp() {
      this.allowToResetPasword = false;
      if (this.details.otp == this.currentUser.otp) {
        this.allowToResetPasword = true;
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "OTP",
          text: "Invalid OTP",
          duration: 10000,
          speed: 1000,
        });
      }
    },
    sendOtp() {
      this.isLoading = true;

      const parms = {
        email: this.details.email,
        userType: this.$route.params.userType,
      };
      this.sendForgetPasswordOTP(parms).then();
    },
    changePassword(){
      if(this.details.password && (this.details.password==this.details.password_confirmation)){
           const parms = {
        password: this.details.password,
        id:this.currentUser.id,
        password_confirmation:this.details.password_confirmation,
        userType: this.$route.params.userType,
      };
       this.isLoading = true;
      this.sendResetPasswordRequest(parms).then();

       
      }else{
         this.$notify({
          group: "app",
          type: "error",
          title: "Reset Password",
          text: "Password and confirm password does not match",
          duration: 10000,
          speed: 1000,
        });
      }
    }
  },
};
</script>
