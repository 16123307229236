<template>
  <div>

    <div>
      <div class="row w-100 mx-0" style="height:100vh">
        <div class="col-lg-3 h-100">
         
        </div>

         <div class="col-lg-6  mt-5 h-100">
          <MazLoader />
        </div>
         <div class="col-lg-3 h-100">
         
        </div>
      
      </div>
    </div>
  </div>
</template>


<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
  export default {
    name: "Authenticate",

    data: function () {
      return {
         hasError:false,
        isLoading: false,
         formErrors: {},
         userType:'',
        details: {
          email: localStorage.getItem("authEmail"),
          password: localStorage.getItem("authPassword"),
           device_type: 'android',
          device_token: this.getDeviceId(),
          device_id: this.getDeviceId(),
          userType:this.userType
        },
      };
    },
    components: {
    },
    computed: {
      ...mapGetters("auth", ["user"]),
      ...mapGetters(["errors", "isSuccess"])
    },

    mounted() {
      this.  details= {
          email: window.localStorage.getItem("authEmail"),
          password: window.localStorage.getItem("authPassword"),
           device_type: 'android',
          device_token: this.getDeviceId(),
          device_id: this.getDeviceId(),
          userType:this.userType
        };
      this.$store.commit("setErrors", {});
      this.$store.commit("setIsSuccess", false);
      this.login();
    },

    methods: {
      ...mapActions("auth", ["sendLoginRequest"]),
         getDeviceId() {
        var navigator_info = window.navigator;
        var screen_info = window.screen;
        var uid = navigator_info.mimeTypes.length;
        uid += navigator_info.userAgent.replace(/\D+/g, '');
        uid += navigator_info.plugins.length;
        uid += screen_info.height || '';
        uid += screen_info.width || '';
        uid += screen_info.pixelDepth || '';
        return uid;
      },
     
      login: function () {

        this.isLoading = true;
        this.details.userType=this.$route.params.userType?this.$route.params.userType:'User';

        this.sendLoginRequest(this.details).then(() => {
          this.isLoading = false;

          if (this.isSuccess) {

            this.details = {};
             localStorage.removeItem("authEmail");
             localStorage.removeItem("authPassword");
             localStorage.setItem("role",  this.$route.params.userType);
             return  window.location.href="/home/"+this.$route.params.userType


          }
        });
      },
    },
  };
</script>
