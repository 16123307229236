<template>
  <div>
    <!-- Navigation -->
    <nav
      id="navbarExample"
      class="navbar navbar-expand-lg fixed-top navbar-light"
      aria-label="Main navigation"
    >
      <div class="container">
        <!-- Image Logo -->
        <router-link
          class="navbar-brand logo-image mb-3"
          :to="{ name: 'Welcome' }"
        >
          <img
            style="max-height: 57px"
            :src="'/images/iposita-ondemand1-alternate-transparent-bg-1-1.png'"
            class="mr-2"
            alt="logo"
          />
        </router-link>

        <button
          class="navbar-toggler p-0 border-0 text-white"
          type="button"
          tyle="color:#fff!important"
          id="navbarSideCollapse"
          @click="menuTogged()"
          aria-label="Toggle navigation"
        >
          <span
            class="navbar-toggler-icon text-white"
            style="color: #fff !important"
          ></span>
        </button>

        <div
          class="navbar-collapse offcanvas-collapse mb-3"
          id="navbarsExampleDefault"
        >
          <ul class="navbar-nav ms-auto navbar-nav-scroll">
            <li class="nav-item">
              <router-link
                class="nav-link"
                aria-current="page"
                :to="{ name: 'Welcome' }"
              >
                Home
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                aria-current="page"
                :to="{ name: 'Welcome' }"
              >
                About us
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                aria-current="page"
                :to="{ name: 'Welcome' }"
              >
                How it works
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                aria-current="page"
                :to="{ name: 'Welcome' }"
                v-scroll-to="'#contactus'"
              >
                Contact us
              </router-link>
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="javascript:void(0);"
                id="dropdown01"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                >En</a
              >
              <ul class="dropdown-menu" aria-labelledby="dropdown01">
                <li>
                  <a class="dropdown-item" href="javascript:void(0);">en</a>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                class="dropdown-toggle btn-solid-sm mt-1 text-center pb-3"
                id="dropdown02"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                href="javascript:void(0);"
                >Sign in</a
              >
              <ul class="dropdown-menu" aria-labelledby="dropdown02">
                <li>
                  <router-link
                    class="dropdown-item"
                    :to="{ name: 'Login', params: { userType: 'User' } }"
                    active-class="active"
                    exact
                  >
                    Customer
                  </router-link>
                </li>
                <li class="mt-1">
                  <router-link
                    class="dropdown-item"
                    :to="{ name: 'Login', params: { userType: 'Driver' } }"
                    active-class="active"
                    exact
                  >
                    Delivery Partner
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <!-- end of navbar-collapse -->
      </div>
      <!-- end of container -->
    </nav>
    <!-- end of navbar -->
    <!-- end of navigation -->

    <!-- Services -->
    <div style="height: 300px" class="cards-1 howIWorks mt-5">
      <div class="container">
        <div class="row mt-4">
          <div class="col-lg-12">
            <div class="card-title pt-4">
              <h1 class="text-center howtiWorkTitle">Terms & Conditions</h1>
            </div>
          </div>
          <!-- end of col -->
        </div>
        <!-- end of row -->
      </div>
      <!-- end of container -->
    </div>

    <div
      class="mt-5"
      :style="setting && setting.terms ? setting.terms.value : viewPrivacyStyle"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-2 col-sm-12"></div>

          <div class="col-lg-8 col-sm-12">
            <MazLoader v-if="isLoading" />
            <p
              class="videoDesc"
              v-html="setting && setting.terms ? setting.terms.value : ''"
            ></p>
          </div>

          <div class="col-lg-2 col-sm-12"></div>
        </div>
        <!-- end of row -->
      </div>
      <!-- end of container -->
    </div>

    <!-- end of form-1 -->
    <!-- end of contact -->

    <!-- Footer -->
    <div id="contactus" class="footer bg-gray">
      <div class="">
        <div class="row mt-3">
          <div class="col-lg-3">
            <div class="d-none d-sm-block">
              <img
                class="float-left text-left ml-5"
                :src="'/images/iposita-ondemand-transparent-logo.png'"
                alt="alternative"
              />
            </div>

            <div class="d-block d-sm-none">
              <img
                class="text-center"
                :src="'/images/iposita-ondemand-transparent-logo.png'"
                alt="alternative"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="p-3">
              <h1 class="CustomerSupport text-center text-white">
                Customer Support
              </h1>
              <p class="ContactInfo mt-3 text-center text-white">
                Contact:
                {{
                  setting && setting.contact_email
                    ? setting.contact_email.value
                    : ""
                }}
                Telephone:
                {{
                  setting && setting.contact_number
                    ? setting.contact_number.value
                    : ""
                }}
                <br />

                {{
                  setting && setting.work_hours ? setting.work_hours.value : ""
                }}
              </p>
            </div>
          </div>
          <div class="col-lg-3 text-white text-center">
            <span class="text-white text-center Connectwithus">
              Connect with us
            </span>
            <div class="row mt-3 text-white">
              <div class="col text-white">
                <a
                  target="_brank"
                  :href="
                    setting && setting.facebook_link
                      ? setting.facebook_link.value
                      : ''
                  "
                >
                  <img
                    class="float-right"
                    src="/images/facebook.png"
                    alt="alternative"
                  />
                </a>
              </div>
              <div class="col text-white">
                <a
                  target="_brank"
                  :href="
                    setting && setting.twitter_link
                      ? setting.twitter_link.value
                      : ''
                  "
                >
                  <img
                    class="float-left"
                    src="/images/twitter.png"
                    alt="alternative"
                  />
                </a>
              </div>
            </div>
          </div>
          <!-- end of col -->
        </div>
        <!-- end of row -->

        <div class="row mt-2">
          <div class="col-lg-12">
            <div class="copyright mt-1">
              <div class="row">
                <div class="col-lg-12 text-white text-center copyrightInfo">
                  {{
                    setting && setting.site_copyright
                      ? setting.site_copyright.value
                      : "2021 iPosita, GridCodes™️ Digital Addressing System All rights reserved."
                  }}
                  <br />
                  <router-link
                    style="color: rgba(249, 178, 24, 1) !important"
                    :to="{ name: 'Privacy' }"
                    active-class="active"
                    exact
                  >
                    Privacy Policy
                  </router-link>
                  |
                  <router-link
                    style="color: rgba(249, 178, 24, 1) !important"
                    :to="{ name: 'Terms' }"
                    active-class="active"
                    exact
                  >
                    Terms & Conditions
                  </router-link>
                  |
                  <router-link
                    style="color: rgba(249, 178, 24, 1) !important"
                    :to="{ name: 'Legal' }"
                    active-class="active"
                    exact
                  >
                    Legal
                  </router-link>
                </div>
                <!-- end of col -->
              </div>
              <!-- end of container -->
            </div>
          </div>
        </div>
      </div>
      <!-- end of container -->
    </div>
  </div>
</template>


<script>
export default {
  name: "Terms",

  data: function () {
    return {
      setting: null,
      isLoading: false,
      viewPrivacyStyle: {
        "background-color": "#ffff",
        height: "600px",
      },
    };
  },
  components: {},
  computed: {},

  mounted() {
    this.isLoading = true;
    Event.$on("settingEvent", (data) => {
      this.isLoading = false;
      this.settingHandler(data);
    });
  },

  methods: {
    settingHandler(setting) {
      this.setting = setting;
    },
    menuTogged() {
      document.querySelector(".offcanvas-collapse").classList.toggle("open");
    },
  },
};
</script>
<style scoped>
@import "/assets/css/bootstrap.min.css";
@import "/assets/css/fontawesome-all.min.css";
/* @import "/assets/css/swiper.css"; */
@import "/assets/css/styles.css";

@import url("https://fonts.googleapis.com/css?family=Roboto:400,700,900,300,500");

:root {
  --black: #000;
  --bay-of-many: rgba(31, 66, 140, 1);
  --killarney: rgba(54, 106, 89, 1);
  --stack: rgba(138, 138, 138, 1);
  --athens-gray: rgba(237, 240, 246, 1);
  --sun: rgba(249, 178, 24, 1);
  --baby-powder: rgba(252, 252, 252, 1);
  --white: rgba(255, 255, 255, 1);

  --font-size-m: 10px;
  --font-size-l: 15px;
  --font-size-xl: 20px;
  --font-size-xxl: 30px;
  --font-size-xxxl: 40px;
  --font-size-xxxxl: 50px;
  --font-family-roboto: "Roboto";

  --font-family-roboto: "Roboto";
}

.maz-dialog__header {
  background: rgba(249, 178, 24, 1) !important;
  color: #fff !important;
}
.bgblue {
  background: rgba(31, 66, 140, 1) !important;
}

.navbar-light {
  background: rgba(31, 66, 140, 1) !important;
  /* position: absolute; */
  height: 85px;
  /* left: 0px;
    top: 0px; */
}
a {
  color: rgba(31, 66, 140, 1);
  text-decoration: none;
}
.navbar .nav-item .nav-link {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  color: #ffffff !important;
  text-decoration: none;
  transition: all 0.2s ease;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
}

.navbar .logo-image img {
  width: 51px !important;
  height: 56px !important;
}
.navbar .nav-item.dropdown.show .nav-link,
.navbar .nav-item .nav-link:hover,
.navbar .nav-item .nav-link.active {
  color: rgba(249, 178, 24, 1) !important;
}
.navbar .btn-solid-sm {
  border-color: rgba(249, 178, 24, 1) !important;
  background-color: rgba(249, 178, 24, 1) !important;
  width: 140px;

  background: #f9b218;
  border-radius: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #000000;
}
.header {
  background-image: url("/images/header_bg.jpg");
  /* background-size: 100% 100%; */
  background-position: right top;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

.section-title1 {
  width: 100%;
  padding: 5px;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 78px;
  line-height: 95px;
  color: #1f428c !important;
}
.h1-large-yellow {
  width: 100%;
  padding: 5px;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 79px;
  line-height: 95px;
  color: rgba(249, 178, 24, 1) !important;
}
.p-large1 {
  width: 100%;
  padding: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  /* or 143% */
  color: #000000;
}

.section-title2 {
  width: 100%;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 22px;
  color: #1f428c !important;
}
.h1-large-yellow2 {
  width: 100%;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 24px;
  color: rgba(249, 178, 24, 1) !important;
}
.p-large2 {
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  /* or 143% */
  color: #000000;
}
@media only screen and (max-width: 768px) {
  .ms-auto {
    margin-left: 1px !important;
  }

  .videoTitle {
    position: relative;
    font-size: 24px !important;
  }
  .videoDesc {
    position: relative;
    margin-top: 8px !important;
    font-size: 17px !important;
  }
  .sendMessageTitle1 {
    display: block !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 26px;
  }
  .hideFooterLogo {
    display: none !important;
  }
  .text-container1 {
    margin-left: 1rem !important;
  }

  .Connectwithus {
    font-size: 26px !important;
    /* line-height: 19px!important; */
  }
  .CustomerSupport {
    font-size: 26px !important;
  }
  .ContactInfo {
    font-size: 12px !important;
  }
}

.quote1 {
  display: inline-block;
  padding: 1rem 1.5rem 1rem 1.5rem;
  width: 294px;
  height: 57px;
  background: #1f418c;
  border: 1px solid #1f418c;
  border-radius: 39px;
  font-family: Roboto !important;
  font-style: normal;
  font-weight: normal;
  font-size: 20px !important;

  color: #ffffff;
}
.bannerYellow {
  width: 100%;
  height: 175px !important;
  background: rgba(249, 178, 24, 0.94);
  background-image: url("/images/leftBanner.svg");
  background-position: left top;
  background-repeat: no-repeat;
}
.youtubeVideo {
  width: 100%;
  height: 438px;
  border-radius: 3px;
}
.videoTitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 44px;
  color: #000000;
}
.videoDesc {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  color: #000000;
}
.howIWorks {
  background: #1f428c;
}
.howtiWorkTitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  text-align: center;

  color: #ffffff;
}
.howIWorks2 {
  background: rgba(31, 66, 140, 0.05);
  background-image: url("/images/howItWork2.svg");
  background-position: right bottom;
  background-repeat: no-repeat;
}
.card-w1 {
  background: #ffffff !important;
  box-shadow: 0px 6px 21px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px !important;
}

.card-w2 {
  background: transparent !important;
  border-radius: 0px !important;
  border: none !important;
}
.card-w3 {
  background: #ffffff !important;
  box-shadow: 0px 6px 21px rgba(0, 0, 0, 0.1) !important;
  border-radius: 0px !important;
  border: none !important;
}
.card-svg {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.cardAppTitle {
  font-family: Roboto !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 25px !important;
  text-align: center !important;
  color: #000000 !important;
}
.cardAppTitle2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;

  text-align: center;

  color: #1f428c;
}
.cardAppTitle4 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;

  text-align: center;

  color: #1f428c;
}
.cardAppDesc {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 21px !important;
  text-align: center;
  color: #000000;
}
.cardAppDesc2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 27px;

  color: #000000;
}
.downloadApp {
  background: #eceff6;
}
.downloadAppTitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 45px;
  line-height: 51px;

  text-align: left;

  color: #000000;
}
.downloadAppDesc {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  line-height: 30px;

  color: #000000;
}
.sendMessageTitle {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  top: 45%;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 45px;
}
.sendMail {
  background: #1f428c;
  background-image: url("/images/sendMailVector.svg");
  background-position: left bottom;
  background-repeat: no-repeat;
}
.form-1 {
  padding-top: 0rem;
  padding-bottom: 0rem;
  text-align: center;
}
.bg-gray {
  background: #58595b;
}
.copyrightInfo {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
}
.Connectwithus {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 29px;
}
.CustomerSupport {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
}
.ContactInfo {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
}

.maz-input__input {
  /* background-image: url("/images/rectangle-30-1.png");
    background-size: 100% 100%;
      border-radius: 7px; */
  border-color: rgba(249, 178, 24, 1);
}
.maz-input {
  /* position: relative;
    background-color: none;
    transition: none;
    height: 1rem;
    min-height: 2.5rem; */
  border-color: rgba(249, 178, 24, 1);
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1620px !important;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 768px) {
  .container-md {
    max-width: 720px;
  }
}
@media (min-width: 576px) {
  .container-sm {
    max-width: 1000px !important;
  }
}
</style>